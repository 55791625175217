@if(!componentWithDataSetter?.component && (dropdownOptions?.length || isField)){
<div
  class="dropdown-list-container rounded-lg max-h-screen overflow-auto border"
  [ngClass]="{'p-3': isField}"
  [style.width]="widthInPX"
  [style.minWidth]="minWidthInPX"
  [style.height.px]="heightInPX"
  [style.maxHeight.px]="maxHeightInPX"
  [ngClass]="{'field-dropdown': isField}"
  >
    @for (option of dropdownOptions; track $index) {
      <button
      [disabled]="option.disabled || selectedOption?.key === option?.key"
      (click)="onOptionClicked(option)"
      [ngClass]="{
        'border-b border-gray-200': ($index !== (dropdownOptions?.length ?? 0) - 1) && !isField,
        'hidden': hiddenOption?.key === option.key || option?.hidden,
        'opacity-50 cursor-initial ': option.disabled || selectedOption?.key === option?.key}"
      class="{{isField ? 'field-option' : 'default-option'}} box-border items-center justify-between w-full flex">
        <div class="rounded-xl option flex gap-[10px] items-center w-full">
          @if(option.prefixIcon){
            <span class="prefix-icon" [innerHTML]="option.prefixIcon | safe"></span>
          }
          <span [style.color]="option?.textColor" class="text-sm text-left" [innerHTML]="option.value | safe"></span>
        </div>
        @if(option?.suffixText){
          <span class="font-medium text-xs leading-4 text-blue-700 text-nowrap">{{option.suffixText}}</span>
        }
      </button>
    } @if(!dropdownOptions?.length){
      <div class="w-full text-center font-medium text-sm leading-[21px] py-2 text-gray-300 select-none" >
        This option is not available
      </div>
    }
  </div>
}
<div [style.maxHeight.px]="containerMaxHeight! + 5" [ngClass]="{'hidden': !componentWithDataSetter?.component && (dropdownOptions?.length || isField)}" class="dropdown-list-container max-h-screen overflow-auto border rounded-lg">
  <ng-template #dynamicTarget></ng-template>
</div>
